import React from 'react';
import { format } from 'date-fns'
import styled from 'styled-components';
import { COLORS } from '../config';
import { LI, UL } from './H1';
import { WrapperCard } from './WrapperCard';

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const MP = styled.div`
  font-weight: ${(props) => (props.bold ? 600 : 400)};
  line-height: 18px;
  font-size: 16px;
`;



const CardBienImmo = ({ lot, to }) => {
  
  let __deliveryDate = (lot?.program?.deliveryDate != undefined) ? format(Date.parse(lot?.program?.deliveryDate.split('T')[0]), 'dd/MM/yyyy') : "";
  
  // 20240118 // Date de livraison spécifique à la réservation
  if (lot?.deliveryDate != undefined) __deliveryDate = format(Date.parse(lot?.deliveryDate.split('T')[0]), 'dd/MM/yyyy');
  
  return (
    <WrapperCard to={to} style={{ display: 'block', overflow: 'hidden' }}>
      <div
        style={{
          marginBottom: 8,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            marginLeft: 8,
            justifyContent: 'space-between',
          }}
        >
          <div>
            <MP bold>
              {lot?.program?.city + ' (' + (lot?.program?.postalcode||"XXNNN") + ')'}
            </MP>
            <MP bold>
              {lot?.program?.name}{' '}
              <span style={{ fontWeight: 400, color: COLORS.secondary }}>
                &nbsp;Lot {lot?.reference}
              </span>
            </MP>
          </div>
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              padding: 8,
            }}
          >
            <span
              className='icon-dbi-pic-_selectionner'
              style={{ fontSize: 20 }}
            ></span>
          </div>
        </div>
      </div>
      <FlexDiv>
        <div style={{ flex: 1, borderTop: `1px solid ${COLORS.disabled}` }}>
          <UL>
            {[
              {
                title: (
                  <>
                    {lot?.area + ' m'}
                    <sup>2</sup>
                  </>
                ),
              },
              { title: (lot?.terrace ? "Terrasse : " + lot?.terrace + " m²" : "")},
              { title: (lot?.balcony ? "Balcon : " + lot?.balcony + " m²" : "")},
              { title: (lot?.loggia ? "Loggia : " + lot?.loggia + " m²" : "")},
              { title: (lot?.garage ? "Garage : " + lot?.garage + " m²" : "")},
              { title: (lot?.garden ? "Jardin : " + lot?.garden + " m²" : "")},
              { title: "Étage : " + (lot?.floor ? lot?.floor : "")},
              { title: 'Livraison : ' +  __deliveryDate },
            ].map((elem, i) => {
              if (elem.title) return <LI style={{ lineHeight: 1.6 }} key={'li_bien_immo_' + i}>{elem.title}</LI>
            })}
          </UL>
        </div>
        <div style={{ flex: 1, textAlign: 'right' }}>
          <img
            style={{
              margin: 0,
              objectFit: 'cover',
              marginRight: -18,
              marginBottom: -17,
            }}
            src={lot?.program?.visuel}
            alt='imageLot'
            width={200}
            height={110}
          />
        </div>
      </FlexDiv>
    </WrapperCard>
  );
};

export { CardBienImmo };
